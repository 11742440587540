import "../styles/index.scss";
import { Object } from "core-js";
require( 'svg-url-loader?stripdeclarations!../assets/icons/crown.svg' );
require( 'svg-url-loader?stripdeclarations!../assets/icons/cherry.svg' );
require( 'svg-url-loader?stripdeclarations!../assets/icons/money.svg' );


const blancError = "can\'t be blank",
  currenciesSelect = document.getElementById( "currencies" ),
  currencyContainer = document.querySelector( ".currencies-container" ),
  currencyError = document.querySelector( ".currency-error" ),
  currecyValidationError = "Please choose a currency",
  email = document.getElementById( "email" ),
  emailContainer = document.querySelector( ".email-container" ),
  emailError = document.querySelector( ".email-error" ),
  emailValidError = "email should include '@'",
  ERRORS = {
    email: { container: ".email-container", errorField: ".email-error" },
    password: { container: ".password-container", errorField: ".password-error" },
  },
  form = document.getElementById( "mobile-form" ),
  offer = document.getElementById( "offer" ),
  offerCheck = document.getElementById( "offer-check" ),
  password = document.getElementById( "password" ),
  passwordContainer = document.querySelector( ".password-container" ),
  passwordError = document.querySelector( ".password-error" ),
  passwordValidError = "Password must be at least 8 characters long",
  preloader = document.getElementById( "preloader" ),
  popup = document.querySelector( ".popup" ),
  terms = document.getElementById( "terms" ),
  termsCheck = document.getElementById( "terms-check" ),
  urlRedirect = "https://7bitcasino.com",
  urlRefparam = "https://www.7bitcasino.com/api/info/locales?stag=",
  usersUrl = 'https://www.7bitcasino.com/api/users';


/*=========================Setup Stag====================*/
function getQueryParam( name ) {
  name = name.replace( /[\[]/, "\\[" ).replace( /[\]]/, "\\]" );
  var regex = new RegExp( "[\\?&]" + name + "=([^&#]*)" );
  var results = regex.exec( location.search );
  return results === null ? "" : decodeURIComponent( results[ 1 ].replace( /\+/g, " " ) );
}

function getCtag() {
  return getQueryParam( "stag" );
}

function setupReferal() {
  const stag = getCtag();
  if ( stag ) {
    fetch( `${ urlRefparam }${ stag }`, {
      method: "GET",
      headers: new Headers( {
        "Content-Type": "application/json; charset=utf-8",
        "Accept-Language": "en",
        "Accept": "application/vnd.softswiss.v1+json"
      } ),
      credentials: "include"
    } );
  }
}

/*=========================Currencies====================*/
function setCurrencies( currencies ) {
  currencies.map( function ( cur ) {
    var node = document.createElement( "option" );
    var textNode = document.createTextNode( cur.code );
    node.appendChild( textNode );
    currenciesSelect.appendChild( node );
  } );
}


function getCurrencies() {
  fetch( "https://www.7bitcasino.com/api/info/currencies",
    {
      "credentials": "omit",
      "headers": {
        "accept": "application/vnd.softswiss.v1+json",
        "sec-fetch-mode": "cors"
      },
      "referrer": "https://7bitcasino.com/",
      "referrerPolicy": "no-referrer-when-downgrade",
      "body": null,
      "method": "GET",
      "mode": "cors"
    } )
    .then( res => res.json() )
    .then( result => setCurrencies( result ) )
    .catch( error => console.error( error ) );
}

/*========================Delete errors================*/
function deleteEmailError() {
  if ( emailError.style.display === "block" ) {
    emailError.removeChild( emailError.childNodes[ 0 ] );
    emailError.style.display = "none";
    emailContainer.classList.remove( "not-valid" );
  }
}

function deletePasswordError() {
  if ( passwordError.style.display === "block" ) {
    passwordError.removeChild( passwordError.childNodes[ 0 ] );
    passwordError.style.display = "none";
    passwordContainer.classList.remove( "not-valid" );
  }
}

function deleteCurrencyError() {
  if ( currencyError.style.display === "block" ) {
    currencyError.removeChild( currencyError.childNodes[ 0 ] );
    currencyError.style.display = "none";
    currencyContainer.classList.remove( "not-valid-currency" );
  }
}

function validTerms() {
  if ( termsCheck.classList.contains( "not-valid-checkbox" ) ) {
    termsCheck.classList.remove( "not-valid-checkbox" );
  }
}

function validOffer() {
  if ( offerCheck.classList.contains( "not-valid-checkbox" ) ) {
    offerCheck.classList.remove( "not-valid-checkbox" );
  }
}

/*=======================Redirect==========================*/
function redirect() {
  popup.style.display = "block";
  setTimeout( function () {
    window.location.href = urlRedirect;
  }, 2000 );
}


/*========================Handle API errors================*/
function errorsHandle( result ) {
  let errorsHandleCount = 0;
  let errorFieldsCount = 0;
  if ( result.errors ) {
    const { errors } = result;
    Object.keys( errors ).map( ( err ) => {
      Object.keys( ERRORS ).map( ( error ) => {
        if ( errorFieldsCount === 0 && err === error ) {
          const errorField = document.querySelector( ERRORS[ error ].errorField );
          const errorContainer = document.querySelector( ERRORS[ error ].container );
          errorFieldsCount++;
          Object.keys( errors[ err ] ).map( ( handleError ) => {
            if ( errorsHandleCount === 0 ) {
              errorContainer.classList.add( "not-valid" );
              const textNode = document.createTextNode( errors[ err ][ handleError ] );
              errorField.appendChild( textNode );
              errorField.style.display = "block";
              errorsHandleCount++;
            }
          } );
        }
      }, errorsHandleCount );
    }, errorFieldsCount );
  } else {
    redirect();
  }

}

/*=========================Set nickname================*/
function setNickname( email ) {
  const nickname = email.substring( 0, email.indexOf( "@" ) );
  return nickname;
}

/*=========================Add user================*/
function addUser() {
  const payload = {
    'user': {
      'email': email.value,
      'password': password.value,
      'currency': currenciesSelect.value,
      'profile_attributes': {
        'terms_acceptance': terms.checked,
        'nickname': setNickname( email.value ),
        'receive_newsletters': true,
        'receive_promos': true
      }
    }
  };

  fetch( usersUrl, {
    method: 'POST',
    body: JSON.stringify( payload ),
    headers: new Headers( {
      "Content-Type": "application/json; charset=utf-8",
      'Accept-Language': 'en',
      'Accept': 'application/vnd.softswiss.v1+json'
    } ),
    credentials: "include"
  } )
    .then( res => res.json() )
    .then( result => {
      if ( !result.id ) {
        if ( result.errors ) {
          errorsHandle( result );
        }
      } else {
        redirect();
      }
    } )
    .catch( err => console.error( err ) )
    .finally( () => {
      form.style.display = "block";
      preloader.style.display = "none";
    } );
  form.style.display = "none";
  preloader.style.display = "flex";
}



/*========================Form submiting================*/
function submitForm( e ) {
  e.preventDefault();
  deleteEmailError();
  deletePasswordError();
  deleteCurrencyError();
  if ( !email.value ) {
    emailContainer.classList.add( "not-valid" );
    const textNode = document.createTextNode( blancError );
    emailError.appendChild( textNode );
    emailError.style.display = "block";
  } else if ( !email.value.includes( "@" ) ) {
    emailContainer.classList.add( "not-valid" );
    const textNode = document.createTextNode( emailValidError );
    emailError.appendChild( textNode );
    emailError.style.display = "block";
  } else if ( !password.value ) {
    passwordContainer.classList.add( "not-valid" );
    const textNode = document.createTextNode( blancError );
    passwordError.appendChild( textNode );
    passwordError.style.display = "block";
  } else if ( password.value.length < 8 ) {
    passwordContainer.classList.add( "not-valid" );
    const textNode = document.createTextNode( passwordValidError );
    passwordError.appendChild( textNode );
    passwordError.style.display = "block";
  } else if ( currenciesSelect.value === "currencies" ) {
    currencyContainer.classList.add( "not-valid-currency" );
    const textNode = document.createTextNode( currecyValidationError );
    currencyError.appendChild( textNode );
    currencyError.style.display = "block";
  } else if ( !offer.checked ) {
    offerCheck.classList.add( "not-valid-checkbox" );
  } else if ( !terms.checked ) {
    termsCheck.classList.add( "not-valid-checkbox" );
  } else {
    addUser();
  }
}

//*===================Fields on focus============*//
email.onfocus = deleteEmailError;
password.onfocus = deletePasswordError;
currenciesSelect.onfocus = deleteCurrencyError;
terms.onchange = validTerms;
offer.onchange = validOffer;

/*========================Functions call================*/
getCurrencies();
setupReferal();


/*========================Add form submit================*/
if ( form ) {
  form.addEventListener( 'submit', submitForm );
}